body {
	background: linear-gradient(to right, #5a709f, #98baf4) !important;
}

.image-sec {
	padding: 20px 0px 20px 20px;
	height: 100%;
}
.HRattrition {
	padding: 50px 100px !important;
}
.HRattrition .card {
	box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px !important;
}
.HRattrition .row {
	max-height: 500px;
}
.ant-form-item .ant-form-item-control-input-content {
	padding: 7px 0px !important;
}
.BVI_logo_sec img {
	width: 15%;
	position: absolute;
	bottom: 11px;
	right: 17px;
}
.login-btn {
	width: 40%;
}
