.iq-top-navbar.fixed-header {
	margin: 0;
	right: 0;
	width: 100%;
	border-radius: 0;

	transition: all 0.45s ease 0s;
}
.iq-top-navbar {
	padding: 0;
	min-height: 50px;
	top: 0;
	left: auto;
	background: #ffffff;
	z-index: 999;
}
.iq-navbar {
	/* color: rgba(8, 155, 171, 1); */
	color: #225994;
	font-size: 27px;
	font-weight: 600;
}
.heading-das {
	margin-left: 20px;
}
.user-info .user-icon,
.logout-sec {
	/* color: rgba(8, 155, 171, 1); */
	color: #225994;
}
.logout-sec .logout {
	/* color: rgba(8, 155, 171, 1); */
	color: #225994;
	text-decoration: none;
	font-weight: bold;
}
/* .iq-user-dropdown {
	display: none;
}

.caption:hover + .iq-user-dropdown {
	display: inline-block;
}

.iq-user-dropdown:hover {
	display: block;
}
.navbar-list li .iq-sub-dropdown .iq-sub-card {
	font-size: inherit;
	padding: 15px;
	line-height: normal;
	color: inherit;
	border-bottom: 1px solid #f3f7fd;
	display: inline-block;
	width: 100%;
} */
/* Navigation.css */
.iq-top-navbar {
	background-color: #fff; /* Change as needed */
	padding: 10px 20px; /* Adjust padding as needed */
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow */
}

.menu-icon {
	cursor: pointer;
}

.user-info {
	align-items: center;
}

.caption {
	cursor: pointer;
}

.caption.active {
	color: #225994; /* Change as needed */
}

.iq-sub-dropdown {
	position: absolute;
	top: 132%;
	right: -10px;
	width: 322px;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	z-index: 999;
	min-height: 160px;
}

.iq-card {
	margin-top: 10px; /* Adjust margin as needed */
}

.iq-card-body {
	padding: 10px; /* Adjust padding as needed */
}

.iq-sub-card {
	display: block;
	color: #333; /* Change as needed */
	text-decoration: none;
	padding: 10px;
}

.iq-sub-card:hover {
	background-color: #f4f4f4; /* Change as needed */
}
.bg-primary {
	color: #ffffff;
	/* border-color: #089bab !important; */
	background: linear-gradient(to bottom, #4c679f, #95bdff);
}
.iq-sign-btn {
	width: 40%;
	color: #ffffff;
	border-color: #089bab !important;
	background: linear-gradient(to right, #4c679f, #95bdff);
	display: inline-block;
	padding: 8px 16px;

	border-radius: 15px; /* Optional border radius */
	text-decoration: none;
}

.iq-sign-btn:hover {
	background: linear-gradient(to right, #95bdff, #4c679f);
	border-color: #225994;
}
.profile_data label {
	margin-right: 10px;
	font-weight: bold;
}
.iq-card-icon {
	height: 44px !important;
	width: 46px !important;
	display: inline-block;
	line-height: 37px !important;
	text-align: center !important;
	font-size: 22px !important;
}
.profile_data {
	font-size: 13px;
	line-height: 1.9;
}
.details {
	border-bottom: 1px solid #545454;
	padding: 5px 0px;
	word-break: break-word;
}
.sidebar-hidden {
	display: none;
}
